import React from 'react';

import { Box } from '@core';

import { LogoList } from '@containers';

const LogoListSection = ({ title, description, data, padding }) => (
  <Box {...padding}>
    <LogoList title={title} description={description} data={data} />
  </Box>
);

export default LogoListSection;
